<template>
  <div class="home-sidebar prel">
    <div class="home-sidebar-group-title fontsize_16 color_222">{{ sidebar_detail.title }}</div>
    <ul class="home-sidebar-group-course fontsize_14" v-if="sidebar_detail.list"
      :class="{ 'media_ul': path_type.indexOf('media') > -1 }">
      <li @click="tapSideItem(item.type)" v-for="item in sidebar_detail.list" :key="item.type"
        class="flex flex_jcbetween flex_acenter course-create-bar" :class="{ 'onlinePhoto': item.type === 'navBack' }">
        <div class="flex flex_acenter flex1 course-create-bar-inner prel">
          <i class="icomoon fontsize_16" :class="[item.iconClass]">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span>
            <span class="path5"></span>
            <span class="path6"></span>
          </i><span class="padding_left16 color_222" :class="{ 'onlinePhoto_title': item.type === 'navBack' }">{{
              item.title
          }}</span>
          <i v-if="item.iconClassRight" :class="[item.iconClassRight]" class="icon-class-right"></i>
        </div>
        <i class="icon-course-add fontsize_12 color_222 couse-create-icon" v-if="item.type === 'createcourse'"></i>
        <i class="icon-share-svg fontsize_16 color_222 couse-create-icon" v-else-if="item.type === 'sharecourse'"></i>
        <i class="icon-media-upload2 fontsize_12 color_222 couse-create-icon"
          v-else-if="item.type === 'uploadmedia'"></i>
      </li>
    </ul>
    <div class="home-sidebar-group-search">
      <el-input @input="handleInput" :placeholder="sidebar_detail.searchNamePlaceholder" v-model="courseKey"
        class="margin_top18 sidebar-input noborder">
        <i slot="prefix" class="input__icon icon-search color_8C fontsize_16"></i>
      </el-input>
      <div class="prel margin_top18">
        <el-select v-if="showTag" v-model="tagname" collapse-tags @change="changeTags" multiple
          :popper-append-to-body="false" :placeholder="sidebar_detail.searchTagPlaceholder"
          @visible-change="tagsVisibleChange" :style="{ '--arrow-down': tagname.length > 0 ? 'none' : 'block'}"
          class="home-tags noborder prel">
          <div class="home-tags-input-wrapper flex flex_acenter">
            <el-input ref="hasInput" :placeholder="$t('lang.searchoftag')" @input="filterTags" :value="tagname2"
              autofocus class="home-tags-input noborder">
              <i class="icon-search padding_right4 fontsize_12 color_999" slot="suffix">
              </i>
            </el-input>
          </div>
          <div slot="empty" class="home-tags noborder prel" style="height:100px;">
            <div class="home-tags-input-wrapper flex flex_acenter" style="position:relative;">
              <el-input ref="emptyInput" :placeholder="$t('lang.searchoftag')" @input="filterTags" :value="tagname2"
                :autofocus="true" class="home-tags-input noborder">
                <i class="icon-search padding_right4 fontsize_12 color_999" slot="suffix">
                </i>
              </el-input>
            </div>
            <div class="flex flex_center flex_acenter fontsize_12 color_ccc margin_top3">无数据</div>
          </div>
          <el-option v-for="item in fileTagList" :key="item.value" :label="item.label" :value="item.value"
            v-show="!item.disabled">
          </el-option>
        </el-select>
        <span v-if="tagname.length" class="clear-tags pointer el-select__caret el-input__icon el-icon-circle-close"
          @click.stop="clearTags"></span>
      </div>
    </div>
    <div class="home-sidebar-group-community width_100p">
      <div class="width_100p flex flex_acenter flex_jcbetween">
        <div class="color_666">
          <!-- <i class="iconmoon icon-user-community fontsize_13"></i> -->
          <img :src="version.icon" alt="" class="home-sidebar-version-icon">
          <span class="padding_left8 fontsize_14 color_222">{{version.name}}</span>
        </div>
        <div class="button__red" @click="handleUpgrade(path_type)">{{ $t('lang.upgrade') }}</div>
      </div>
      <el-progress :percentage="version.course_percent" color="#E74362" :show-text="false" class="margin_top6 progress_bgc_FFCCD6">
      </el-progress>
      <p class="fontsize_12 color_A1A1A1 margin_top8" v-if="path_type === 'home' || path_type === 'share'">{{ $t('lang.numberofcourse') }} <span
          class="color_E74362">{{version.use_course_num}}</span>/{{version.course_num}}</p>
      <p class="fontsize_12 color_A1A1A1 margin_top8" v-if="path_type === 'media'">{{ $t('lang.spacecapacity') }} <span
          class="color_E74362">{{version.use_zone}}</span>/{{version.zone}}{{version.zone_type}}</p>
    </div>
    <fd-dialog v-if="dialog_detail" :title="dialog_detail.title" :visible.sync="dialogVisible" width="600px"
      :before-close="handleClose" custom-class="course-dialog">
      <el-form @submit.native.prevent>
        <div v-for="ditem in dialog_detail.fieldList" :key="ditem.id" class="dialog-form-item">
          <p class="margin_bot7 fontsize_14 color_222"><span>{{ ditem.label }}</span><span
              class="padding_left4 color_D0021B" v-if="ditem.type === 'uploadFile'">*</span></p>
          <el-input v-if="ditem.type === 'text'" v-model="othersParams[ditem.propsName]" autocomplete="off"
            :placeholder="ditem.placeholder" class="media-edit-input noborder"></el-input>
          <el-select v-else-if="ditem.type === 'selectAllowCreate'" v-model="othersParams[ditem.propsName]"
            value-key="tag_name" multiple filterable allow-create default-first-option remote
            :remote-method="remoteTags" @change="changeSelectTag($event, ditem.propsName)"
            :placeholder="ditem.placeholder">
            <el-option v-for="item in fileTagList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div v-else-if="ditem.type === 'uploadFile'">
            <el-upload v-show="fileList.length === 0" class="upload-demo" ref="uploadFile" name="file"
              :headers="headers" :action="https+'/api/V1/upload/file'" :data="{
                name: othersParams.name,
                description: othersParams.description,
                tag_name: othersParams.tag_name,
              }" :multiple="false" :auto-upload="false" :show-file-list="false" :on-change="uploadChange"
              :on-success="uploadSuccess" :file-list="fileList">
              <button size="small" type="primary"
                class="dialog-form-upload flex flex_acenter flex_center fontsize_12 color_222 pointer"><i
                  class="icon-course-add"></i><span class="padding_left8">上传文件</span></button>
            </el-upload>
            <div v-show="fileList.length !== 0">
              <div v-for="fitem in fileList" :key="fitem.id" class="upload-list padding_top8">
                <span class="fontsize_14 color_4A90E2">{{ fitem.original_name || fitem.name }}</span>
                <i class="icon-remove-picture margin_left16 fontsize_16 pointer remove-picture"
                  @click="clearUploadFile"></i>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button @click="save" class="course-save pointer">保存</button>
        <button @click="closeDialog" class="course-close pointer">关闭</button>
      </span>
    </fd-dialog>
    <fd-upgrade-dialog :closeOnClickModal="false" :show.sync="upgradeDialogVisible" @close="handleUpgradeClose"></fd-upgrade-dialog>
    <temp-dialog :visible.sync="tempdialog"></temp-dialog>
    <hint-dialog :visible.sync="tipVisible" :hint="hintObj" @click="toUpgrade" :ismore="true"></hint-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { getToken } from '@/libs/utils/auth';
import { addMediaTag } from '@/libs/api/media';
import { getInfo } from '@/libs/api/login';
import shareDialog from '@/renderer/components/common/shareDialog.vue';
export default {
  components:{
    shareDialog
  },
  props: {
    showTag: {
      type: Boolean,
      default: false
    },
    tagList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      https:process.env.VUE_APP_API,
      token: "",
      sidebars: {
        'home': {
          title: this.$t('lang.mycourse'),
          searchNamePlaceholder: this.$t('lang.searchofcoursename'),
          searchTagPlaceholder: this.$t('lang.searchoftag'),
          list: [
            {
              iconClass: 'icon-create-course',
              title: this.$t('lang.createcourse'),
              type: 'createcourse'
            },
            // {
            //   iconClass: 'icon-import-course',
            //   title: this.$t('lang.importcourse'),
            //   type:'importcourse'
            // }
          ],
        },
        'media': {
          title: this.$t('lang.medialibrary'),
          searchNamePlaceholder: this.$t('lang.searchofname'),
          searchTagPlaceholder: this.$t('lang.searchoftag'),
          list: [
            {
              iconClass: 'icon-media-upload',
              title: this.$t('lang.mediaupload'),
              type: 'uploadmedia'
            },
            {
              iconClass: 'icon-online-photos',
              title: this.$t('lang.onlinePhotoGallery'),
              type: 'navPhotoGallery'
            }
          ]
        },
        'mediaphotoGallery': {
          title: this.$t('lang.onlinePhotoGallery'),
          searchNamePlaceholder: this.$t('lang.searchofname'),
          searchTagPlaceholder: this.$t('lang.searchoftag'),
          list: [
            {
              iconClass: 'icon-photos_navBack',
              title: "返回上一页",
              type: 'navBack',
              iconClassRight: 'icon-photos_backArrow'
            }
          ]
        },
        'share': {
          title: this.$t('lang.sharingcenter'),
          searchNamePlaceholder: this.$t('lang.searchofcoursename'),
          searchTagPlaceholder: this.$t('lang.searchoftag'),
          list: [
            {
              iconClass: 'icon-share-kx',
              title: this.$t('lang.sharelessons'),
              type: 'sharecourse'
            },
            // {
            //   iconClass: 'icon-import-course',
            //   title: this.$t('lang.importcourse'),
            //   type:'importcourse'
            // }
          ],
        },
      },
      othersParams: {
        name: "",
        description: "",
        tag_name: ""
      },
      dialogSet: {
        'media': {
          title: "上传新媒体",
          fieldList: [
            {
              id: 201,
              type: "uploadFile",
              label: "文件",
              uploadtext: "上传文件",
            },
            {
              id: 202,
              type: "text",
              label: "文件名称",
              placeholder: "请输入文件名称",
              propsName: "name"
            },
            {
              id: 203,
              type: "text",
              label: "文件描述",
              placeholder: "请输入文件描述",
              propsName: "description"
            },
            {
              id: 204,
              type: "selectAllowCreate",
              label: "文件标签",
              placeholder: "请输入标签，回车建立标签",
              propsName: "tag_name_list"
            }
          ],
        },
      },
      dialogVisible: false,
      inputVal: "",
      courseKey: "",
      tagname: "",
      tagname2: "",
      tagShow: false, //标签下拉框是否显示
      tagsBack: [],
      fileList: [], //媒体文件上传
      fileTagList: [], //文件标签列表
      oldTag: [], //已选中 文件标签
      upgradeDialogVisible: false,
      version:{},
      shareVisible:false,
      tempdialog:false,
      hintObj: {},
      tipVisible:false
    };
  },
  computed: {
   
    headers() {
      return {
        Authorization: 'Bearer ' + this.token
      }
    },
    sidebar_detail() {
      // console.log('sidebar_detail',this.$route.path.replace(/\//,""))
      return this.sidebars[this.$route.path.replace(/\//g, "")] || {}
    },
    dialog_detail() {
      return this.dialogSet[this.$route.path.replace(/\//, "")] || {}
    },
    path_type() { // home media  share 三个页面
      return this.$route.path.replace(/\//g, "");
    }
  },
  watch: {
    '$route': {
      handler(n, o) {
        this.courseKey = "";
        this.tagname = "";
        this.tagname2 = "";
        this.tagShow = false;
      },
      immediate: true,
      deep: true,
    },
    tagList: {
      handler(n, o) {
        if (n) {
          for (let i in n) {
            n[i].label = n[i].tag_name;
            n[i].value = n[i].tag_name;
          }
          this.tagsBack = n;
          this.fileTagList = n;
        }
      },
      immediate: true,
    }
  },
  async created() {
    const token = await getToken();
    this.token = token;
    this.getUserInfo()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    // 去升级
    toUpgrade() {
      this.tempdialog = false;
      this.tipVisible = false;
      this.upgradeDialogVisible = true;
    },
    getUserInfo(){
      this.GetUserInfo().then(res=>{
        if (res.errorcode == 0) {
          let { zone,use_zone} = res.data.version;
          res.data.version.scale=(use_zone/zone)*100;
          this.version=res.data.version;
        }
      })
    },
    changeSelectTag(val, prop) {
      // 选中值发生变化
      let newVal = this.othersParams[prop].filter(v => {
        return !this.oldTag.includes(v)
      });

      this.oldTag.push(...newVal);
      newVal.forEach(v => {
        addMediaTag(v).then(res => {
          if (res.errorcode == 0) {

          }
        })
      })
    },
    remoteTags(query) {
      // 远程搜索标签
      if (query === '') {
        this.fileTagList = this.tagList;
        return;
      }
      this.GetMediaTagList(query).then(res => {
        if (res.errorcode == 0) {
          this.fileTagList = res.data.data;
          this.$forceUpdate();
        }
      }).catch();
    },
    clearTags() {
      // 清空标签
      this.tagname = [];
      this.$emit("changeTags", "");
    },
    changeTags(val) {
      this.$emit("changeTags", val);
    },
    handleInput(val) {
      this.$emit("searchList", { pathType: this.path_type, keyword: val || "" });
    },
    tapSideItem(type) {
      switch (type) {
        case 'createcourse':
          this.tempdialog=true;
          // this.addCourse();
          break;
        case 'importcourse':
          console.log('未实现课程导入');
          this.$message.info("课程导入暂未开放！");
          break;
        case 'uploadmedia':
          this.$emit('showUploadMedia');
          break;
        case 'navPhotoGallery':
          // 跳转图片库
          this.$emit('navPhotoGallery');
          this.$router.push("/media/photoGallery");
          break;
        case 'navBack':
          this.$emit('navBack');
          this.$router.push("/media");
          break;
        case 'sharecourse':
          this.$emit('toShare')
          // this.$emit('navBack');
          // this.$router.push("/media");
      }
    },
    uploadSuccess(response, file, fileList) {
      if (response.errorcode == 0) {
        this.dialogVisible = false;
        this.fileList = [];
        this.fileList.push(response.data)
      } else {
        this.$message.warning(response.msg);
      }
    },
    uploadChange(file, fileList) {
      // console.log('uploadChange',file, fileList);
      this.fileList = fileList;
    },
    handleClose(done) {
      // 清空处理
      for (let key in this.othersParams) {
        this.othersParams[key] = "";
      }
      this.clearUploadFile(); //清空文件列表
      done();
    },
    closeDialog() {
      this.dialogVisible = false;
      for (let key in this.othersParams) {
        this.othersParams[key] = "";
      }
      this.clearUploadFile(); //清空文件列表
    },
    save() {
      // 上传单个文件
      if (this.fileList.length == 0) {
        this.$message("请上传文件！");
        return;
      }
      if (Array.isArray(this.$refs.uploadFile)) {
        this.$refs.uploadFile[0].submit();
      } else {
        this.$refs.uploadFile.submit();
      }
    },
    clearUploadFile() {
      // 清空已上传的文件列表
      if (Array.isArray(this.$refs.uploadFile)) {
        this.$refs.uploadFile[0].clearFiles();
      } else {
        this.$refs.uploadFile.clearFiles();
      }
      this.fileList = [];
      // 文件删除后 文件名也自动删除
      this.othersParams['name'] = "";
    },
    addCourse(obj) {
      console.log(obj)
      // this.GetSchema();
      this.CreateCourse({...obj}).then(res => {
        this.$router.push('/course/edit/' + res.data.course_id + "?pageType=index");
        // this.$navBlank({
        //   path: "/course/edit/" + res.data.course_id,
        //   query: {
        //     pageType: "index"
        //   },
        //   pageId: res.data.course_id
        // });
      }).catch((err) => {
        console.log('创建课程失败', err)
        if(err && ( err.errorcode === 10010 || err.errorcode === 10011 )) {
          this.$message.closeAll()
          this.hintObj = {
            content: err.msg,
            version: err.data.version
          }
          this.tipVisible = true;
        }
      });
    },
    tagsVisibleChange(visible) {
      this.tagShow = visible;
      if (!visible) {
        this.tagname2 = "";
        this.fileTagList = this.tagsBack;
      }
    },
    filterTags(val) {
      this.tagname2 = val;
      if (!val) {
        this.fileTagList = this.tagsBack;

        this.focusInput();
        return;
      }
      this.fileTagList = [].concat(this.tagsBack.filter(v => v.label.indexOf(val) > -1))

      this.focusInput();
    },
    focusInput() {
      //搜索时，为空，select内部只显示了empty导致
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.emptyInput && this.$refs.emptyInput.focus();
          this.$refs.hasInput && this.$refs.hasInput.focus();
        }, 50);
      });
    },
    
    // ---升级---
    handleUpgrade(path_type) {
      this.upgradeDialogVisible = true;
    },
    handleUpgradeClose(done) {
      // done();
    },
    closeShareDialog(done){
      console.log('done :>> ', done);
      // this.shareVisible=false;
      done()
    }
  
  },
}
</script>
<style lang="less" scoped>
.home-sidebar {
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 0 8px;
  width: 220px;
  height: 100%;
  background-color: #FFFFFF;
}

.home-sidebar-group-title {
  padding: 19px 8px;
}

.home-sidebar-group-course {
  position: relative;
  padding-bottom: 1px;

  li {
    margin: 0 0 8px;
    padding: 0 8px 0;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    right: 0;
    bottom: 1px;
    transform: translateX(-50%);
    width: calc(100% - 16px);
    border-bottom: 1px solid #F0F0F0;
  }

  &.media_ul {
    padding-bottom: 10px;
  }
}

.home-sidebar-group-search {
  padding: 0 8px;
  .sidebar-input {
    background-color: #F5F5F5;
  }
  /deep/.el-input__inner {
    padding-left: 36px;
  }
}

.home-sidebar-group-search /deep/input::-webkit-input-placeholder
{
  
    font-size: 14px;
    color: #BFBFBF;

}

li.course-create-bar {
  transition: all 0.3s;

  &:hover {
    background-color: #ededed;
  }

  &.onlinePhoto {
    &:hover {
      background-color: transparent;
    }
  }

  .couse-create-icon {
    visibility: hidden;
  }

  &:hover {
    .couse-create-icon {
      visibility: visible;
    }
  }
}

.onlinePhoto {
  .onlinePhoto_title {
    color: #fff;
  }

  .course-create-bar-inner {
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 16px;
    background-color: #E74362;
  }
}

.icon-class-right {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.home-tags-input-wrapper {
  position: absolute;
  top: 0;
  z-index: 3;
  box-sizing: border-box;
  padding: 12px;
  border-bottom: 1px solid #EEEEEE;
  background-color: #FFF;
}

.home-tags-input {
  box-sizing: border-box;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  line-height: 29px;
  font-size: 12px;
  color: #333;

  /deep/.el-input__inner {
    padding-left: 8px;
    background-color: #fff !important;
  }

  /deep/.el-input__inner::placeholder {
    font-size: 12px;
    color: #999;
  }
}

.home-sidebar-group-community {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  padding: 16px 8px;
  // 图标
  .home-sidebar-version-icon {
    position: relative;
    bottom: -2px;
    width: 14px;
    height: 14px;
  }
}

.home-tags {
  height: 40px;
  line-height: 40px;
  /deep/.el-select-dropdown {
    left: 0 !important;
  }
  /deep/.el-input {
    height: auto;
    background-color: #F5F5F5;
  }

  /deep/.el-icon-arrow-up {
    // display: none;
    display: var(--arrow-down); //根据是否有选中标签控制显示与否
  }

  /deep/.el-input__inner {
    background-color: #F5F5F5;
  }

  /deep/.el-select-dropdown__wrap {
    padding-top: 56px;
  }

  /deep/.el-select-dropdown__item {
    position: relative;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    color: #222222;
    padding-right: 0;
    padding-left: 32px;

    >span {
      display: inline-block;
      max-width: 147px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .hover,
    &.selected.hover {
      background-color: #EDEDED;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 8px;
      display: block;
      content: "";
      width: calc(100% - 16px);
      height: 1px;
      background-color: #EDEDED;
    }
  }

  /deep/.el-tag {
    // border: 1px solid #E74362;
    border-radius: 4px;
    max-width: 79px;
    height: 26px;
    font-size: 12px;
    color: #fff;
    background-color: #E74362;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }
  }

  /deep/.el-tag__close.el-icon-close {
    font-size: 14px;
    color: #E74362;
    background-color: #fff;
  }

  /deep/.el-select__tags {
    margin-left: 36px;

    &+.el-input .el-input__inner {
      background: url(~@/assets/svg/tag-search.svg) no-repeat center center; //引入icon
      background-size: 16px 16px; //这个是图片的大小，在这里不能直接设置width  height,设置宽高其实是select的宽高，图片可能会失真只设置宽度  高度auto也行
      background-position: 12px 12px; //在input中定位置  可以调整自定义icon的左右上下位置
      padding: 0 0 0 36px; //需要设置padding 把placeholder向右移
      box-sizing: border-box;
      font-size: 14px;
    }
  }

  // 未被选中
  .el-select-dropdown.is-multiple .el-select-dropdown__item::before {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translate(0, -50%);
    content: "\e99f";
    display: block;
    font-family: 'icomoon';
    color: #d4d4d4;
    font-size: 16px;
  }

  // 被选中select项
  .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
    position: absolute;
    bottom: 0;
    left: 8px;
    display: block;
    content: "";
    width: calc(100% - 16px);
    height: 1px;
    background-color: #EDEDED;
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::before {
    content: "\e92e";
    color: #e74362;
  }
}

/deep/.el-dialog__body {
  padding-top: 20px;
  padding-bottom: 32px;
}

/deep/.el-dialog__footer {
  padding-bottom: 32px;
}

button.dialog-form-upload {
  box-sizing: border-box;
  margin-top: 13px;
  border: 1px solid #CCCCCC;
  border-radius: 18px;
  width: 116px;
  height: 36px;
  line-height: 34px;
  background: #F0F0F0;
}

/deep/.el-upload-list {
  display: none;
}

.upload-list {
  height: 24px;
  line-height: 24px;

  .remove-picture {
    vertical-align: middle;
    padding: 3px;
  }
}


</style>
