<template>
  <div>
    <el-menu
      class="home-navbar flex flex_acenter flex_jcbetween"
      mode="horizontal"
    >
      <el-dropdown
        @command="handleUser"
        @visible-change="userVisibleChange"
        class="home-user-menu"
        placement="top-start"
      >
        <span class="el-dropdown-link fontsize_14 color_222 flex flex_acenter">
          <el-image :src="logosrc" fit="cover" class="home-logo flexshrink"></el-image>
          <div class="flex flex_acenter home-user-main">
            <img :src="avatar" alt="" class="home-user-avatar">
            {{ username
            }}<i
              class="el-icon--right fontsize_14"
              :class="{
                'el-icon-arrow-up': showUserControl,
                'el-icon-arrow-down': !showUserControl,
              }"
            ></i>
          </div>
        </span>
        <el-dropdown-menu slot="dropdown" class="height46 home-user-dropdown">
          <el-dropdown-item
            command="currentuser"
            class="border_bottomED1"
            style="margin-bottom: 4px"
          >
            <div
              class="
                dropmenu_user
                flex1
                flex flex_acenter
                padding_left8 padding_right8
              "
            >
              <img v-if="avatar" :src="avatar" alt="" class="user_avatar" />
              <i v-else class="icon-default_avatar fontsize_24"></i>
              <span class="fontsize_14 color_8C padding_left8">{{
                username
              }}</span>
              <i
                class="
                  user-icon
                  iconmoon
                  icon-check-symbol
                  padding_left20
                  fontsize_14
                "
              ></i>
            </div>
          </el-dropdown-item>
          <!-- <el-dropdown-item command="adduser" divided><i class="icon-add-user fontsize_14" style="color:#333"></i><span class="fontsize_14 color_222">{{$t('lang.adduser')}}</span></el-dropdown-item> -->
          <el-dropdown-item command="payment" class="flex flex_acenter">
            <div class="flex1 flex flex_acenter dropmenu_item_inner">
              <i
                class="icon-paymentAndOrder fontsize_16 color_222 icon-person"
                style="color: #333"
              ></i
              ><span class="fontsize_14 color_26">{{
                $t("lang.paymentandorder")
              }}</span>
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="personal" class="flex flex_acenter">
            <div class="flex1 flex flex_acenter dropmenu_item_inner">
              <i
                class="icon-personal_data fontsize_16 color_222 icon-person"
                style="color: #333"
              ></i
              ><span class="fontsize_14 color_26">{{
                $t("lang.personaldata")
              }}</span>
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="security" class="flex flex_acenter">
            <div
              class="flex1 flex flex_acenter dropmenu_item_inner"
              style="margin-bottom: 4px"
            >
              <i
                class="icon-account_security fontsize_16 color_222 icon-person"
                style="color: #333"
              ></i
              ><span class="fontsize_14 color_26">{{
                $t("lang.accountsecurity")
              }}</span>
            </div>
          </el-dropdown-item>
          <el-dropdown-item
            command="exit"
            class="flex flex_acenter border_topED1"
          >
            <div
              class="flex1 flex flex_acenter dropmenu_item_inner"
              style="margin: 4px 0 6px"
            >
              <i
                class="icon-exit fontsize_16 color_222 icon-person"
                style="color: #333"
              ></i
              ><span class="fontsize_14 color_26">{{
                $t("lang.logout")
              }}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="home-navtabs flex flex1 flex_center">
        <div
          class="flexshrink"
          :class="{ active: tabsIndex == tind }"
          v-for="(item, tind) in tabs"
          :key="item.value"
          @click="changeTab(tind, item)"
        >
          <div
            class="fontsize_16 home-navtabs-item"
            :class="{ 'active color_222': tabsIndex == tind, 'color_26': tabsIndex != tind }"
          >
            {{ $t("lang." + item.langlabel) || item.name }}
          </div>
        </div>
      </div>
      <div
        class="home-navhelp flex flex_acenter flex_end fontsize_14 color_222"
      >
        <el-dropdown
          class="home-msg-menu"
          @visible-change="msgVisibleChange"
          trigger="click"
          ref="msgMenu"
        >
          <div class="el-dropdown-link">
            <div
              class="
                flex
                flex_acenter
                fontsize_14
                color_222
                prel
                dropdown-link-msg
              "
              ><img src="@/assets/svg/nav_msg.svg" class="msg-img" /><span class="msg-text">消息</span><i class="red-dot" v-if="msgTotal>0">{{msgTotal > 99 ? '99+' : msgTotal}}</i></div
            >
            <!-- <i
              class="el-icon--right el-icon-arrow-down"
              style="color: #c0c4cc"
              :class="{ active: showMsgControl }"
            ></i> -->
          </div>
          <el-dropdown-menu slot="dropdown" class="flex">
            <div class="msg-dropdown" @click="tapMsgDropdown">
              <div class="tabs-header">
                <span
                  v-for="(mitem, mind) in msgTabs"
                  :key="mitem.value"
                  @click.stop="changeMsgTab(mitem, mind)"
                  class="tabs-header-item pointer"
                  :class="{
                    color_6E6E6E: msgData.tabIndex != mind,
                    color_E74362: msgData.tabIndex == mind,
                    active: msgData.tabIndex == mind,
                  }"
                  >{{ mitem.name }} <span v-if="mitem.num">({{ mitem.num }})</span></span
                >
              </div>
              <div class="tabs-content">
                <div
                  class="
                    tabs-clear-unread
                    flex flex_end
                    fontsize_12
                    color_6E6E6E
                  "
                >
                  <div
                    class="btn flex flex_acente pointer"
                    @click="handleAllRead"
                  >
                    <i class="icon-all_read fontsize_20">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </i>
                    <span class="btn-text lineheight_20">全部已读</span>
                  </div>
                </div>
                <div
                  v-for="(citem, cind) in currentMsgList"
                  :key="'MsgList_' + cind"
                  @click="tapMsg(citem, cind)"
                  class="tabs-news-item flex pointer"
                  :class="{ hasRead: citem.is_read == 1 }"
                >
                  <div class="read-status">
                    <i
                      :class="{
                        'icon-unread': citem.is_read == 0,
                        'icon-markRead': citem.is_read == 1,
                      }"
                    ></i>
                  </div>
                  <div class="tabs-news-body">
                    <p class="fontsize_14 color_222 pover">{{ citem.title }}</p>
                    <div class="pover2 fontsize_12 color_6E6E6E content" v-html="citem.content"></div>
                    <p class="fontsize_12 color_A1A1A1">
                      {{ citem.created_at }}
                    </p>
                  </div>
                </div>
                <div class="tabs-tips flex flex_acenter flex_center">
                  <span class="fontsize_12 color_A1A1A1"
                    >仅显示6个月内的通知</span
                  >
                </div>
              </div>
              <router-link
                to="/news"
                class="
                  tabs-footer
                  notunderline
                  flex flex_acenter flex_center
                  fontsize_12
                  color_6E6E6E
                  pointer
                "
              >
                <span class="padding_right4">历史消息</span
                ><i class="el-icon-arrow-right"></i>
              </router-link>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        
        <!-- <el-select v-model="currentLanguage" placeholder="" @change="languageChange" popper-class="homelanguage" :popper-append-to-body="false" class="language-select noborder"> 
          <img slot="prefix" class="language-img" src="@/assets/svg/language_chinese.svg" alt="">
          <el-option
            v-for="item in languageList"
            :key="item.key"
            :label="item.name"
            :value="item.key">
          </el-option>
        </el-select> -->
        <a
          :href="'https://bbs.fei-dao.com/'"
          target="_blank"
          class="
            home-helplink
            flexshrink
            fontsize_14
            color_222
            flex flex_acenter
          "
        >
          <i class="icon-community fontsize_16"></i>
          <span class="margin_left4">{{ $t("lang.community") }}</span>
        </a>
      </div>
    </el-menu>
    <fd-dialog
      :title="msgDialogSet.title"
      :visible.sync="msgDialogSet.visible"
      width="788px"
      :before-close="(arg) => beforeCloseDialog(arg, 'msg')"
      custom-class="course-dialog not-full msg-dialog"
    >
      <div class="msg-detail">
        <p class="fontsize_16 color_222 margin_bot8">
          {{ msgDialogSet.item.title }}
        </p>
        <p class="fontsize_12 color_A1A1A1 margin_bot16">
          {{ msgDialogSet.item.created_at }}
        </p>
        <p
          class="msg-detail-content fontsize_14 color_6E6E6E"
          v-html="msgDialogSet.item.content"
        ></p>
      </div>
    </fd-dialog>
  </div>
</template>

<script>
import logoPic from "@/assets/logoWithText.svg";
import { mapMutations, mapState } from "vuex";
import {
  getMessageList,
  getMessageDetail,
  setMessageRead,
} from "@/libs/api/message";
export default {
  components: {},
  data() {
    return {
      logosrc: logoPic,
      showUserControl: false,
      showMsgControl: false,
      tabs: [
        { name: "课程", langlabel: "course", value: "course", url: "/home" },
        { name: "媒体", langlabel: "media", value: "media", url: "/media" },
        { name: "分享", langlabel: "share", value: "share", url: "/share" },
        { name: "模板", langlabel: "templet", value: "templet", url: "/templet" },
      ],
      tabsIndex: 0,
      currentLanguage: "zh-CN",
      msgTabs: [
        { name: "全部", num: 0, value: "all" },
        { name: "提醒", num: 0, value: "notice" },
        { name: "公告", num: 0, value: "adminnews" },
      ],
      msgData: {
        tabIndex: 0,
        content: {
          all: [],
          remind: [],
          notice: [],
        },
      },
      msgDialogSet: {
        title: "消息详情",
        visible: false,
        item: {},
      },
      msgTotal:0
    };
  },
  computed: {
    ...mapState({
      is_web: state => state.app.isWebSide,
      username: (state) => state.user.userInfo.username,
      avatar: (state) => state.user.userInfo.avatar,
      languageList: (state) => state.others.languageList,
      feidaoLanguage: (state) => state.others.feidaoLanguage,
    }),
    currentMsgValue() {
      // 当前展开value
      return this.msgTabs[this.msgData.tabIndex].value;
    },
    currentMsgList() {
      // 当前消息列表
      return this.msgData.content[this.currentMsgValue];
    },
  },
  watch: {
    '$route': {
      deep: true,
      handler(n) {
        let curIndex = this.tabs.findIndex(v => {
          return n.path.indexOf(v.url) >= 0
        })
        if(curIndex >= 0) this.tabsIndex = curIndex;
      }
    },
    feidaoLanguage: {
      handler(n) {
        if (n) {
          this.currentLanguage = n;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    let { fullPath } = this.$route;
    let curentUrl = this.tabs[this.tabsIndex].url;
    if (fullPath !== curentUrl) {
      let targetInd = this.tabs.findIndex((v) => fullPath.indexOf(v.url) > -1);
      if (!targetInd) return;
      this.changeTab(targetInd, this.tabs[targetInd], false);
    }
    this.getMsgList();
  },
  methods: {
    ...mapMutations(["setCurrentLanguage"]),
    tapMsgDropdown() {
      // 点击消息下拉菜单任意一项，关闭下拉菜单
      this.$refs.msgMenu && this.$refs.msgMenu.hide();
    },
    beforeCloseDialog(done) {
      done();
    },
    tapMsg(item) {
      if (item.is_read==1) {
        this.msgDialogSet.item = JSON.parse(JSON.stringify(item));
        this.msgDialogSet.visible = true;
      }else{
        getMessageDetail(item.id).then(res=>{
          if (res.errorcode==0) {
            this.msgDialogSet.item = JSON.parse(JSON.stringify(res.data));
            this.msgDialogSet.visible = true;
            this.getMsgList();
          }
        })
      }
      
    },
    changeMsgTab(item, index) {
      if (index === this.msgData.tabIndex) return;
      this.msgData.tabIndex = index;
      this.getMsgList(item.value);
    },
    handleUser(command) {
      switch (command) {
        case "currentuser":
          break;
        case "adduser":
          console.log("跳转添加用户");
          break;
        case "personal":
          // 跳转个人资料
          this.$router.push("/personal");
          break;
        case "security":
          // 账户安全
          this.$router.push("/personal/account");
          break;
        case "payment":
          // 支付与订单
          // this.$message.info("暂未开放！");
          this.$router.push("/personal/order");
          break;
        case "exit":
          // 退出登录
          this.logout();
        default:
          break;
      }
    },
    userVisibleChange(isShow) {
      this.showUserControl = isShow;
    },
    msgVisibleChange(isShow) {
      this.showMsgControl = isShow;
    },
    changeTab(index, item, isJump = true) {
      if (this.tabsIndex == index) return;
      // if(item.value == "share") {
      //   this.$message.info("暂未开放！");
      //   return;
      // }
      this.tabsIndex = index;
      isJump && this.$router.push({ path: item.url });
    },
    languageChange(val) {
      // 语言切换
      // val : en cn
      let lang = "";
      switch (val) {
        case "zh-CN":
          lang = "zh";
          break;
        case "en":
          lang = "en";
          break;
      }
      this.$i18n.locale = lang;
      this.setCurrentLanguage(val);
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    },
    getMsgList(type = "all") {
      getMessageList({ type: type=='all'?'':type, is_page: 0, limit: 20 ,page:0})
        .then((res) => {
          if (res.errorcode == 0) {
            // this.msgData.content[type] = res.data;
            this.$set( this.msgData.content,  type, res.data.data);
            
            let allNum = 0;
            let noticeNum = 0;
            let remindNum = 0;
            res.data.data.forEach((v) => {
              if (v.is_read == 0) {
                allNum += 1;
              }
              if (v.is_read == 0 && v.type == "notice") {
                noticeNum += 1;
              }
              if (v.is_read == 0 && v.type == "adminnews") {
                remindNum += 1;
              }
            });
            // this.msgTotal=allNum;
            // this.msgTabs[0].num = allNum;
            // this.msgTabs[1].num = noticeNum;
            // this.msgTabs[2].num = remindNum;
            this.msgTotal=res.data.notreadnum.all;
            this.msgTabs[0].num = res.data.notreadnum.all;
            this.msgTabs[2].num = res.data.notreadnum.adminnews;
            this.msgTabs[1].num = res.data.notreadnum.notice;
          }
        })
        .catch((err) => {});
    },
    handleAllRead() {
      if (!this.currentMsgList.length) return;
      setMessageRead().then((res) => {
        if (res.errorcode == 0) {
          this.$message.success("操作成功");
          this.getMsgList();
        }
      });
    },
  },
};
</script>

<style  lang="less" scoped>
// 样式重置
.el-select {
  /deep/.el-input__inner {
    font-size: 14px;
    color: #222;
  }
  /deep/.el-input--suffix .el-input__inner {
    padding-right: 4px;
  }
}
.home-navhelp {
  padding-right: 16px;
  .el-select {
    padding-left: 15px;
    width: 48px;
    border-radius: 13px;
    padding: 5px 8px;
  }
  &>*:not(:first-child) {
    margin-left: 4px;
  }
}
.language-select {
  &:hover {
    background-color: #f5f5f5;
  }
  /deep/.el-input__prefix {
    left: 0;
  }
  /deep/.el-input__suffix {
    display: none;
  }
  /deep/.el-input__inner {
    text-indent: 20px;
    padding: 0 !important;
  }
  .language-img {
    width: 15px;
    height: 15px;
  }
}
.homelanguage {
  .el-select-dropdown__item {
    height: 40px;
    line-height: 40px;
  }
}

.home-logo {
  margin-top: -3px;
  margin-right: 15px;
  // width: 36px;
  // height: 22px;
  width: 50px;
  height: 26px;
}
.home-navbar {
  position: relative;
  z-index: 999;
  height: 60px;
  // line-height: 60px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-bottom: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  .home-navtabs-item {
    position: relative;
    line-height: 60px;
    padding: 0 24px;
    cursor: pointer;
    &.active {
      font-weight: bolder;
      &::after {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) translateY(0);
        content: "";
        display: block;
        width: 44px;
        height: 2px;
        background-color: #e74362;
      }
    }
    &:hover {
      font-weight: bolder;
    }
  }
  .home-helplink {
    padding: 5px 8px;
    text-decoration: none;
    // color: #e74362;
    border-radius: 13px;
    &:hover {
      background-color: #f5f5f5;
    }
  }
  .home-user-menu {
    padding: 10px 16px;
    .el-dropdown-link {
      display: flex;
      i {
        margin-bottom: 3px;
      }
    }
    .home-user-main {
      padding: 3px 10px;
      background: #F5F5F5;
      border-radius: 15.5px;
    }
    .home-user-avatar {
      margin-right: 9px;
      display: block;
      border-radius: 50%;
      width: 24px;
      height: 24px;
    }
  }
  .home-navhelp {

  }
  .home-user-menu {
    // width: 222px;
  }
}
.icon-person {
  padding: 15px 12px;
}
/deep/li.el-dropdown-menu__item {
  padding: 0 8px;
  transition: none;
  & i {
    margin-right: 0;
  }
}
/deep/.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff;
  .dropmenu_item_inner {
    border-radius: 4px;
    background: #f5f5f5;
  }
}

// 消息
.home-msg-menu {
  margin-right: 6px;
  border-radius: 13px;
  padding: 5px 8px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  .el-dropdown-link {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  /deep/.el-icon-arrow-down {
    transition: transform 0.3s;
    &.active {
      transform: rotateZ(180deg);
    }
  }
  .msg-img {
    width: 16px;
    height: 16px;
  }
  .msg-text {
    padding-left: 4px;
  }
}

// 消息下拉弹窗
.msg-dropdown {
  position: relative;
  box-sizing: border-box;
  padding: 0 8px;
  width: 310px;
  height: 480px;
  border: 1px solid #ededed;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background: #ffffff;
  // 头部tab
  .tabs-header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 45px;
    line-height: 44px;
    border-bottom: 1px solid #ededed;
  }
  .tabs-header-item {
    position: relative;
    flex-shrink: 0;
    flex: 1;
    text-align: center;
    font-size: 14px;
    &.active {
      &::after {
        position: absolute;
        bottom: 0px;
        left: 0;
        display: block;
        content: "";
        width: 100%;
        height: 2px;
        background-color: #e74362;
      }
    }
  }
  // 全部已读
  .tabs-clear-unread {
    right: 0;
    height: 36px;
    .btn {
      cursor: pointer;
      padding: 5px 18px 5px 14px;
    }
    .btn-text {
      padding-left: 6px;
    }
  }
  // 列表部分
  .tabs-content {
    padding-right: 4px;
    margin-right: -6px;
    overflow: hidden auto;
    height: calc(100% - 122px + 36px);
    &::-webkit-scrollbar-thumb {
      background: #a1a1a1;
      border-radius: 2px;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
  }
  .tabs-news-item {
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 18px 12px 16px;
    width: 100%;
    height: 114px;
    &:hover {
      background: #ededed;
    }
    &.hasRead {
      .tabs-news-body > p {
        color: #a1a1a1;
      }
    }
  }
  .read-status {
    padding-right: 8px;
  }
  .tabs-news-body {
    width: 238px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .content {
      height: 36px;
      line-height: 18px;
    }
  }
  .tabs-footer {
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 0;
    border-top: 1px solid #d8d8d8;
    line-height: 40px;
  }
  .tabs-tips {
    position: relative;
    padding: 12px 0;
    &::before,
    &::after {
      display: block;
      content: "";
      width: 73px;
      height: 1px;
      background-color: #d8d8d8;
      position: absolute;
    }
    &::before {
      left: 8px;
    }
    &::after {
      right: 8px;
    }
  }
}
.red-dot {
  position: absolute;
  right: 5px;
  transform: translate(100%, -100%);
  top: 5px;
  font-style: normal;
  border-radius: 6px;
  padding: 0 3px;
  box-sizing: border-box;
  min-width: 12px;
  height: 12px;
  line-height: 12px;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  background: #FF003C;
}
// 消息详情
.msg-detail {
  box-sizing: border-box;
  overflow: hidden auto;
  height: 531px;
  padding: 22px 28px 20px 32px;
  .msg-detail-content {
    line-height: 40px;
    /deep/img {
      width: 100%;
      object-fit: contain;
    }
  }
}
.dropmenu_user {
  line-height: 56px;
}
.user_avatar {
  border-radius: 50%;
  object-fit: cover;
  width: 24px;
  height: 24px;
}
</style>

