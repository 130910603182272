<template>
    <div class="home-sidebar prel">
      <div class="home-sidebar-group-title fontsize_16 color_26">{{ sidebar_detail.title }}</div>
      <ul class="home-sidebar-group-temp fontsize_14" v-if="sidebar_detail.list">
        <li @click="tapSideItem(item,index)" v-for="(item,index) in lists" :key="item.id"
          class="temp-create-bar pover" :class="{'current':index==current}">
          {{ item.title }}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { getToken } from '@/libs/utils/auth';
  export default {
    components:{
    },
    props: {
      lists:[Array]
    },
    data() {
      return {
        token: "",
        current:0,
        sidebars: {
          'templet': {
            title: this.$t('lang.templatecenter'),
            list: [],
          }
        },
      };
    },
    computed: {
      headers() {
        return {
          Authorization: 'Bearer ' + this.token
        }
      },
      sidebar_detail() {
        // console.log('sidebar_detail',this.$route.path.replace(/\//,""))
        return this.sidebars[this.$route.path.replace(/\//g, "")] || {}
      },
    },
    watch: {
      '$route': {
        handler(n, o) {
        },
        immediate: true,
        deep: true,
      },
    },
    async created() {
      const token = await getToken();
      this.token = token;
    },
    methods: {
    tapSideItem(item,index){
        this.current = index;
        this.$emit('click',item)
    },
    },
  }
  </script>
  <style lang="less" scoped>

  .home-sidebar {
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 0 12px;
    width: 220px;
    height: 100%;
    background: #ffffff;
    border-radius: 4px;
  }
  
  .home-sidebar-group-title {
    padding: 20px 0;
  }
  
  .home-sidebar-group-temp {
    position: relative;
    padding-bottom: 1px;
  
    li {
      border-radius: 4px;
      height: 38px;
      line-height: 38px;
      cursor: pointer;
      padding-left: 44px;
      font-size: 14px;
      color: #595959;
      margin-bottom: 6px;
    }
  }
  
  li.temp-create-bar {
    padding-right: 16px;
    transition: all 0.3s;
    &:hover {
      background-color: #F7E7EA;
    }
  
    &.current {
        background-color: #E74362;
        color: #fff;
    }
  }
  
  
  
  /deep/.el-dialog__body {
    padding-top: 20px;
    padding-bottom: 32px;
  }
  
  /deep/.el-dialog__footer {
    padding-bottom: 32px;
  }
  
  </style>
  