<template>
  <section class="home-main flex1">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain'
}
</script>
<style lang="less" scoped>
  .home-main {
    // padding: 20px 0 5px 32px;
  }

</style>
